@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 1000ms;
@value transitionOut: 1000ms;
.intro {
  overflow-x: hidden;
}
.intro.appear, .intro.enter {
  opacity: 0;
}
.intro.appearing, .intro.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.intro.exit {
  opacity: 1;
}
.intro.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.intro a {
  text-decoration: none;
  color: #31302a;
}
.intro .content-inner {
  position: static;
  justify-content: normal;
}
@media only screen and (min-aspect-ratio: 4/2) {
  .intro .content-inner {
    position: static;
    justify-content: normal;
  }
}
@media only screen and (max-width: 767px) {
  .intro .content-inner {
    position: static;
  }
}
.intro .content {
  position: relative;
  display: flex;
  height: calc(100vh - 4.7916666667vw);
  flex-direction: column;
  background-size: cover;
  background-position: 50% 11%;
  background-repeat: no-repeat;
}
@media only screen and (min-aspect-ratio: 4/2) {
  .intro .content {
    height: auto;
    min-height: calc(100vh - 4.7916666667vw);
  }
}
@media only screen and (max-width: 767px) {
  .intro .content {
    display: block;
    height: auto;
    padding-top: 86.71875vw;
    background-position: 0 -1%;
    background-size: 100%;
  }
}
.intro .content .main-logo {
  position: relative;
  display: flex;
  width: 39.0625vw;
  margin: 0 auto 0.5729166667vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-logo {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0 3.125vw;
    flex-direction: column;
  }
}
.intro .content .main-logo .main-logo-bg {
  position: relative;
  width: 8.28125vw;
  margin-top: -3.6458333333vw;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-logo .main-logo-bg {
    position: inherit;
    left: 0;
    width: 24.84375vw;
    margin-top: 0vw;
    margin-bottom: 0.46875vw;
  }
}
.intro .content .main-logo .main-logo-bg img {
  width: 100%;
}
.intro .content .main-logo .title {
  padding-top: 0.9895833333vw;
  font-size: 3.28125vw;
  line-height: 0.44;
  letter-spacing: -1.7px;
  color: #e31b23;
  font-family: "NarkisBlock-Bold", sans-serif;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-logo .title {
    width: 100%;
    padding-top: 0vw;
    font-size: 9.84375vw;
    line-height: 0.86;
    letter-spacing: -0.13px;
    text-align: center;
    white-space: normal;
  }
}
.intro .content .main-content {
  width: 42.1354166667vw;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content {
    width: 100%;
    max-width: 100%;
    padding: 2.5vw 3.125vw 5.625vw 3.125vw;
  }
}
.intro .content .main-content .description {
  width: 40.625vw;
  margin: 0 auto;
  padding: 0.78125vw 0 0.78125vw 0.5208333333vw;
  font-size: 1.3541666667vw;
  line-height: 1.08;
  font-family: "NarkisBlock", sans-serif;
  color: #000;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .description {
    width: 100%;
    padding: 0 6.25vw 4.375vw;
    font-size: 4.53125vw;
    line-height: 1.1;
  }
}
.intro .content .main-content .description.with-big-padding {
  padding-bottom: 2.0833333333vw;
  width: 39.0625vw;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .description.with-big-padding {
    width: 100%;
  }
}
.intro .content .main-content .description span {
  font-family: "opensanshebreew_bold", sans-serif;
  font-size: 1.25vw;
  font-stretch: normal;
  letter-spacing: -0.96px;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .description span {
    font-family: "Open Sans", sans-serif;
    font-size: 5.15625vw;
  }
}
.intro .content .main-content .description span i {
  font-family: "NarkisBlock-Medium", sans-serif;
  font-style: normal;
  font-size: 1.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .description span i {
    font-size: 5.78125vw;
  }
}