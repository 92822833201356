@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .footer {
    position: relative;
  }
}
.footer .footer-links {
  padding: 0.46875vw 0 0.2604166667vw;
  background-color: transparent;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links {
    padding: 5.3125vw 0 2.1875vw;
    font-size: 3.28125vw;
    line-height: 1.71;
    letter-spacing: -0.42px;
    background-color: #fff;
  }
}
.footer .footer-links .footer-links-wrapper {
  display: flex;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.footer .footer-links .footer-links-wrapper .terms {
  position: relative;
  display: flex;
  padding-left: 0.8333333333vw;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .terms {
    padding-left: 4.0625vw;
  }
}
.footer .footer-links .footer-links-wrapper .terms::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.390625vw;
  width: 0.0520833333vw;
  height: 0.8333333333vw;
  background-color: #000;
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .terms::before {
    left: 2.03125vw;
    width: 1px;
    height: 2.5vw;
  }
}
.footer .footer-links .footer-links-wrapper .terms a {
  display: inline-block;
  color: #000;
  font-family: "NarkisBlockCondensedMF-Thin", sans-serif;
  letter-spacing: -0.4px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .terms a {
    letter-spacing: -0.48px;
  }
}
.footer .footer-links .footer-links-wrapper .privacy {
  position: relative;
  display: flex;
  padding-left: 0.8333333333vw;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .privacy {
    padding-left: 4.0625vw;
  }
}
.footer .footer-links .footer-links-wrapper .privacy::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.390625vw;
  width: 0.0520833333vw;
  height: 0.8333333333vw;
  background-color: #000;
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .privacy::before {
    left: 2.03125vw;
    width: 1px;
    height: 2.5vw;
  }
}
.footer .footer-links .footer-links-wrapper .privacy a {
  display: inline-block;
  color: #000;
  font-family: "NarkisBlockCondensedMF-Thin", sans-serif;
  letter-spacing: -0.4px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .privacy a {
    letter-spacing: -0.48px;
  }
}
.footer .footer-links .footer-links-wrapper .yit a {
  display: flex;
  align-items: center;
}
.footer .footer-links .footer-links-wrapper .yit a span {
  color: #000;
  font-family: "NarkisBlockCondensedMF-Thin", sans-serif;
  letter-spacing: -0.28px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .yit a span {
    letter-spacing: -0.38px;
  }
}
.footer .footer-links .footer-links-wrapper .yit a img {
  display: inline-block;
  height: 1.09375vw;
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .footer .footer-links .footer-links-wrapper .yit a img {
    width: 5vw;
    height: auto;
    margin-right: 4px;
    margin-bottom: -3px;
  }
}