@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@media only screen and (max-width: 767px) {
  .form {
    padding: 4.6875vw 2.34375vw 0;
  }
}

.form-row:nth-child(2) .field-wrapper {
  margin-bottom: 0;
}
.form-row.with-checkboxes {
  padding: 13px 0 6.5px;
}
@media only screen and (max-width: 767px) {
  .form-row.with-checkboxes {
    padding: 0 0 3.046875vw;
  }
}
.form-row.with-button {
  position: relative;
  display: flex;
  padding-top: 0.9375vw;
  margin-bottom: 0.5729166667vw;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button {
    padding-top: 0.78125vw;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
}
.form-row.with-button .btn-wrapper {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .btn-wrapper {
    margin-top: 4.375vw;
  }
}
.form-row.with-button .submit-btn {
  width: 17.0833333333vw;
  height: 2.7083333333vw;
  padding: 0;
  font-size: 1.7708333333vw;
  line-height: 1.29;
  letter-spacing: 2.21px;
  border: 0;
  font-family: "NarkisBlock-Medium", sans-serif;
  background-color: #e31b23;
  border-radius: 50px;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn {
    width: 88.59375vw;
    height: 12.1875vw;
    padding: 0;
    line-height: 12.1875vw;
    font-size: 6.875vw;
    border-radius: 6.09375vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row.with-text-area {
    padding-top: 1.09375vw;
  }
}
.form-row .fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-row .field-wrapper {
  position: relative;
  width: 13.4895833333vw;
  margin-bottom: 1.3020833333vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper {
    width: 100%;
    margin-bottom: 5vw;
  }
}
.form-row .field-wrapper .word-counter {
  position: absolute;
  left: 0.5208333333vw;
  bottom: 0.46875vw;
  line-height: 1.31;
  font-size: 0.6770833333vw;
  color: #979393;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .word-counter {
    left: 4.84375vw;
    bottom: 2.8125vw;
    font-size: 2.96875vw;
  }
}
.form-row .field-wrapper .text-area-additional-text {
  position: absolute;
  bottom: 0.46875vw;
  right: 0.5208333333vw;
  font-size: 0.6770833333vw;
  line-height: 1.31;
  color: #b4a2a2;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .text-area-additional-text {
    bottom: 2.8125vw;
    right: 3.125vw;
    font-size: 2.96875vw;
  }
}
.form-row .field-wrapper .inner-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-row .field-wrapper .inner-fields-wrapper .inner-field-wrapper {
  width: 3.0729166667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .inner-fields-wrapper .inner-field-wrapper {
    width: 24%;
  }
}
.form-row .field-wrapper .inner-fields-wrapper .inner-field-wrapper.long-field {
  width: 10.1041666667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .inner-fields-wrapper .inner-field-wrapper.long-field {
    width: 73%;
  }
}
.form-row .field-wrapper.short-field {
  width: 3.3854166667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.short-field {
    width: 13.59375vw;
  }
}
.form-row .field-wrapper.full-width-field {
  width: 100%;
}
.form-row .field-wrapper.long-field {
  width: 27.7604166667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.long-field {
    width: 100%;
  }
}
.form-row .row-title {
  padding-bottom: 0.3125vw;
  font-size: 1.3020833333vw;
  line-height: 1.16;
  letter-spacing: 0.58px;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .form-row .row-title {
    padding-top: 1.25vw;
    padding-bottom: 2.96875vw;
    font-size: 5vw;
    line-height: 5vw;
    letter-spacing: normal;
  }
}
.form-row .checkbox-wrapper {
  padding-left: 1.6666666667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper {
    width: 100%;
    padding-top: 5.15625vw;
    padding-right: 0;
    padding-bottom: 2.8125vw;
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper span {
    font-size: 2.96875vw;
    letter-spacing: 0.44px;
    font-weight: normal;
  }
}
.form-row .checkbox-wrapper br {
  display: none;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper br {
    display: block;
  }
}
.form-row .checkbox-wrapper .info {
  display: flex;
  align-items: center;
  font-family: "NarkisBlock", sans-serif;
  font-size: 0.9375vw;
  font-weight: 100;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.88px;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper .info {
    padding-bottom: 12px;
    font-size: 3.59375vw;
    line-height: 1.17;
    letter-spacing: 1.13px;
  }
}
.form-row .checkbox-wrapper .info span {
  display: inline-block;
  padding-top: 0.2604166667vw;
  padding-left: 0.3125vw;
  font-size: 1.1979166667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.13px;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper .info span {
    padding-top: 1px;
    padding-left: 3px;
    font-size: 4.0625vw;
  }
}

.file-placeholder {
  font-family: "opensanshebreew_regular", sans-serif;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  animation-name: preloader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.preloader circle {
  stroke: #fff;
  stroke-width: 4px;
  stroke-dasharray: 45, 45;
  stroke-dashoffset: 0;
}

@keyframes preloader-animation {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(2) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale(2) rotate(360deg);
  }
}