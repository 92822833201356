@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.sended-form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  direction: ltr;
  background-color: rgba(40, 41, 40, 0.78);
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
}
.sended-form .title {
  font-size: 42px;
  letter-spacing: 0.71px;
  line-height: 40px;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: black;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sended-form .title {
    font-size: 6.5625vw;
  }
}
.sended-form .text {
  font-size: 24px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .sended-form .text {
    font-size: 3.75vw;
    line-height: 4.375vw;
  }
}
.sended-form .emailWrapper {
  font-size: 24px;
  line-height: 28px;
  color: #1f52a4;
  font-family: "NarkisBlock-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .emailWrapper {
    font-size: 3.75vw;
    line-height: 4.375vw;
  }
}
.sended-form .emailWrapper .email {
  font-size: 23px;
  line-height: 28px;
  color: #04ab6c;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .emailWrapper .email {
    font-size: 3.125vw;
    line-height: 4.375vw;
  }
}
.sended-form .footer-text {
  margin-top: 44px;
  font-size: 36px;
  line-height: 28px;
  font-family: "NarkisBlock-Bold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .footer-text {
    margin-top: 6.875vw;
    font-size: 5.625vw;
  }
}
.sended-form .sended-form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 566px;
  height: 262px;
  padding-right: 22px;
  padding-left: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  font-family: "NarkisBlock-Medium", sans-serif;
  font-size: 42px;
  line-height: 40px;
  letter-spacing: 0.71px;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper {
    width: 90%;
    height: 40.9375vw;
    max-width: 88.4375vw;
    padding-right: 3.125vw;
    padding-left: 3.125vw;
    font-size: 6.5625vw;
    line-height: 6.25vw;
    letter-spacing: 0.71px;
  }
}
.sended-form .sended-form-wrapper p {
  text-align: center;
}
.sended-form .sended-form-wrapper p span {
  font-family: "NarkisBlock-Bold", sans-serif;
}
.sended-form .sended-form-wrapper .close-btn {
  position: absolute;
  top: 11px;
  left: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close-btn {
    top: 1.5625vw;
    left: 1.5625vw;
    width: 3.75vw;
    height: 3.75vw;
  }
}
.sended-form .sended-form-wrapper .close-btn::after, .sended-form .sended-form-wrapper .close-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 2px;
  height: 24px;
  background-color: #e31b23;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close-btn::after, .sended-form .sended-form-wrapper .close-btn::before {
    width: 0.3125vw;
    height: 4.0625vw;
  }
}
.sended-form .sended-form-wrapper .close-btn::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.sended-form .sended-form-wrapper .close-btn::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.sended-form[class*=show] {
  opacity: 1;
  visibility: visible;
}