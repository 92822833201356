@charset "UTF-8";
/* stylelint-disable order/order */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.text-field {
  position: relative;
  display: block;
  text-align: left;
}
.text-field.active .placeholder {
  padding: 0 0.2083333333vw;
  top: -0.3125vw;
  font-size: 0.625vw;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .text-field.active .placeholder {
    font-size: 2.96875vw;
    padding: 0 0.3125vw;
    top: -1.5625vw;
    right: 4.6875vw;
  }
}
.text-field.active .placeholder.for-textarea {
  padding: 0 0.3125vw;
  top: -0.5208333333vw;
  font-size: 0.8854166667vw;
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .text-field.active .placeholder.for-textarea {
    font-size: 2.96875vw;
    padding: 0 0.3125vw;
    top: -2.5vw;
    right: 4.6875vw;
  }
}
.text-field.error input {
  border: solid 0.1041666667vw #e31b23;
}
@media only screen and (max-width: 767px) {
  .text-field.error input {
    border: solid 0.3125vw #e31b23;
  }
}
.text-field.error .placeholder {
  color: #e31b23;
}
.text-field.error .textarea-wrapper {
  border: solid 0.1041666667vw #e31b23;
}
@media only screen and (max-width: 767px) {
  .text-field.error .textarea-wrapper {
    border: solid 0.3125vw #e31b23;
  }
}
.text-field input {
  display: block;
  width: 100%;
  height: 1.7708333333vw;
  padding: 0 0.5208333333vw;
  line-height: 1.1458333333vw;
  border-radius: 0.8854166667vw;
  background-color: #fff;
  border: solid 0.1041666667vw #979393;
  outline: none;
  font-size: 0.8333333333vw;
  font-family: "NarkisBlock", sans-serif;
  color: #000;
  -webkit-appearance: none;
}
@media only screen and (max-width: 767px) {
  .text-field input {
    height: 8.90625vw;
    padding: 0 3.125vw;
    border: solid 0.3125vw #979393;
    line-height: 6.25vw;
    border-radius: 4.53125vw;
    font-size: 3.59375vw;
  }
}
.text-field input.inline {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #707070;
}
.text-field input.ltr {
  direction: ltr;
}
.text-field input:-internal-autofill-selected, .text-field input:-webkit-autofill, .text-field input:-webkit-autofill:hover, .text-field input:-webkit-autofill:focus, .text-field input:-webkit-autofill:active {
  -webkit-text-fill-color: #000 !important;
  box-shadow: 0 0 0 30px white inset !important;
  background-color: white !important;
  color: rgb(0, 0, 0) !important;
  transition: background-color 600000s 0s, color 600000s 0s;
}
.text-field .textarea-wrapper {
  height: 11.40625vw;
  border-radius: 0.625vw;
  border: solid 0.1041666667vw #979393;
  padding-bottom: 1.5625vw;
  padding-top: 0.5208333333vw;
}
@media only screen and (max-width: 767px) {
  .text-field .textarea-wrapper {
    height: 64.53125vw;
    padding-top: 2.8125vw;
    border-radius: 3.75vw;
    border: solid 0.3125vw #979393;
    padding-bottom: 6.25vw;
  }
}
.text-field textarea {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0vw 0.5208333333vw 0.5208333333vw;
  background-color: #fff;
  border: none;
  outline: none;
  font-size: 0.9895833333vw;
  line-height: 1.09375vw;
  font-family: "NarkisBlock", sans-serif;
  color: #000;
  resize: none;
  -webkit-appearance: none;
}
@media only screen and (max-width: 767px) {
  .text-field textarea {
    padding: 0 1.875vw 1.875vw;
    line-height: 4.375vw;
    font-size: 4.0625vw;
  }
}
.text-field .placeholder {
  position: absolute;
  top: 0.4166666667vw;
  right: 0.5208333333vw;
  font-family: "NarkisBlock", sans-serif;
  font-weight: 100;
  font-size: 0.8333333333vw;
  letter-spacing: 0.48px;
  text-align: right;
  transition: all ease 0.3s;
  color: #979393;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder {
    top: 2.34375vw;
    right: 3.125vw;
    font-size: 3.59375vw;
    letter-spacing: 0;
  }
}
.text-field .placeholder.hide {
  display: none;
}
.text-field .placeholder.for-textarea {
  top: 0.5208333333vw;
  right: 0.5208333333vw;
  font-size: 0.9895833333vw;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder.for-textarea {
    top: 2.65625vw;
    left: 2.03125vw;
    right: 3.125vw;
    font-size: 3.59375vw;
  }
}
.text-field .error-message {
  position: absolute;
  bottom: -0.8854166667vw;
  right: 0.5208333333vw;
  font-size: 0.7291666667vw;
  color: #e31b23;
  font-family: "NarkisBlock", sans-serif;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .text-field .error-message {
    bottom: -3.75vw;
    right: 3.125vw;
    font-size: 2.8125vw;
  }
}